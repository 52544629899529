import { useRef } from "react";
import { closeOutline } from "ionicons/icons";
import {
  IonIcon,
  IonItem,
  IonPage,
  IonTitle,
  IonHeader,
  IonButton,
  IonButtons,
  IonContent,
  IonToolbar,
  IonTextarea,
} from "@ionic/react";

export interface INewMessageModal {
  message: string;
}

const NewMessageModal = ({
  onDismiss,
}: {
  onDismiss: (data?: INewMessageModal | null, role?: string) => void;
}) => {
  const messageRef = useRef<HTMLIonTextareaElement>(null);
  const handleSubmit = (proceed: boolean, reason: string) => {
    if (proceed) {
      const data: INewMessageModal = {
        message: messageRef.current?.value?.toString() || "",
      };
      onDismiss(data, reason);
    } else {
      onDismiss(null, reason);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => handleSubmit(false, "cancel")}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Message</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSubmit(true, "confirm")}>
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem>
          <IonTextarea ref={messageRef} rows={5} label="Message" placeholder="Am I Audible" />
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default NewMessageModal;

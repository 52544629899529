import { CLIENT_FOCUS_EVENTS } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export interface IRoomState {
  focus_state: CLIENT_FOCUS_EVENTS.YIELD_TOTAL_FOCUS | undefined;
  user_count: number;
  roomId: string;
  name: string;
}

export const roomState = createSlice({
  name: "roomState",
  initialState: {
    focus_state: { focus: 0, total: 0 },
    user_count: 0,
    roomId: "xxx",
    name: "xxx",
  },
  reducers: {
    saveUserCount: (state, action) => {
      state.user_count = +action.payload.count;
    },
    saveFocusState: (state, action) => {
      state.focus_state = action.payload;
    },
    saveRoomDetails: (state, action) => {
      state.name = action.payload.name;
      state.roomId = action.payload.roomId;
    },
  },
});

export const { saveUserCount, saveRoomDetails, saveFocusState } = roomState.actions;

export default roomState.reducer;

import { getEditorTheme } from "../../utils/common/helper";
import { addOutline, closeOutline } from "ionicons/icons";
import { useRef, useState } from "react";
import TimerButton from "../TimerButton";
import AceEditor from "react-ace";
import {
  IonCol,
  IonRow,
  IonPage,
  IonItem,
  IonCard,
  IonIcon,
  IonGrid,
  IonInput,
  IonLabel,
  IonTitle,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonItemDivider,
} from "@ionic/react";

export interface IChoiceQuestionTemplateModal {
  answer: string;
  second: string;
  title: string;
  code?: string;
  text: string;
  options: string[];
};

const ChoiceQuestionTemplateModal = ({
  onDismiss,
}: {
  onDismiss: (data?: IChoiceQuestionTemplateModal | null, role?: string) => void;
}) => {
  const textRef = useRef<HTMLIonInputElement>(null);
  const titleRef = useRef<HTMLIonInputElement>(null);
  const answerRef = useRef<HTMLIonInputElement>(null);
  const [second, setSecond] = useState<number>(30);
  const [source, setSource] = useState<string>("");
  const [options, setOptions] = useState<{ id: number; value: string }[]>([]);

  const handleSubmit = (proceed: boolean, reason: string) => {
    if (proceed) {
      const data: IChoiceQuestionTemplateModal = {
        options: options.map((option) => option.value).filter(e => e.trim()),
        answer: answerRef.current?.value?.toString() || '',
        title: titleRef.current?.value?.toString() || '',
        text: textRef.current?.value?.toString() || '',
        code: source,
        second: String(second),
      };
      if (data.options.length == 0) return;
      onDismiss(data, reason);
    } else {
      onDismiss(null, reason);
    }
  };

  const handleInputChange = (optionId: number, value: string) => {
    const updatedOptions = options.map((option) => option.id === optionId ? { ...option, value } : option);
    setOptions(updatedOptions);
  };
  const handleRemoveOption = (optionId: number) => {
    const updatedOptions = options.filter((option) => option.id !== optionId);
    setOptions(updatedOptions);
  };
  const handleAddOption = () => {
    const newOption = { id: Date.now(), value: "" };
    setOptions([...options, newOption]);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => handleSubmit(false, "cancel")}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Choice</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSubmit(true, "confirm")}>
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonInput ref={titleRef} label="Title" placeholder="Brain checker" />
        </IonItem>
        <IonItem>
          <IonInput ref={textRef} label="Text" placeholder="1 meaning in binary" />
        </IonItem>
        <IonItem>
          <IonInput ref={answerRef} label="Answer" placeholder="3" />
        </IonItem>
        <IonCard className="h-40 p-2">
          <AceEditor
            name={`ID-${Math.random()}`}
            theme={getEditorTheme()}
            mode={"text"}
            width="100"
            fontSize={16}
            highlightActiveLine={false}
            showPrintMargin={true}
            readOnly={false}
            showGutter={false}
            wrapEnabled={false}
            style={{ height: "100%" }}
            placeholder="Code Here [Optional]"
            editorProps={{ $blockScrolling: true }}
            onLoad={(editor) => { editor.renderer.setPadding(10) }}
            // value={``}
            // debounceChangePeriod={1000}
            onChange={setSource}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
          />
        </IonCard>
        <IonItemDivider />
        <IonItem>
          <IonLabel>Seconds</IonLabel>
          <IonButtons slot="end">
            <TimerButton text="30" active={second === 30} onClick={() => setSecond(30)} />
            <TimerButton text="60" active={second === 60} onClick={() => setSecond(60)} />
            <TimerButton text="90" active={second === 90} onClick={() => setSecond(90)} />
            <TimerButton text="120" active={second === 120} onClick={() => setSecond(120)} />
            <TimerButton text="300" active={second === 300} onClick={() => setSecond(300)} />
            <TimerButton text="600" active={second === 600} onClick={() => setSecond(600)} />
            <TimerButton text="1200" active={second === 1200} onClick={() => setSecond(1200)} />
          </IonButtons>
        </IonItem>
        <IonButton expand="full" onClick={handleAddOption}>Add Option</IonButton>
        <IonGrid className="space-y-2">
          {options.map((option, i) => (
            <IonRow key={i}>
              <IonCol size="10" className="p-0">
                <AceEditor
                  name={`ID-${Math.random()}`}
                  theme={getEditorTheme()}
                  mode={"text"}
                  width="100"
                  fontSize={14}
                  highlightActiveLine={false}
                  showPrintMargin={true}
                  readOnly={false}
                  showGutter={false}
                  wrapEnabled={false}
                  value={option.value}
                  style={{ height: "100%" }}
                  placeholder={`${i + 1}. Enter Option`}
                  editorProps={{ $blockScrolling: true }}
                  // debounceChangePeriod={1000}
                  onChange={(e) => handleInputChange(option.id, e)}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    showLineNumbers: false,
                    tabSize: 2,
                  }}
                />
              </IonCol>
              <IonCol size="2">
                <IonButtons>
                  <IonButton onClick={() => handleRemoveOption(option.id)}>
                    <IonIcon icon={closeOutline} />
                  </IonButton>
                  <IonButton onClick={handleAddOption}>
                    <IonIcon icon={addOutline} />
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ChoiceQuestionTemplateModal;

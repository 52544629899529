import socketHost from "../../socket-host";
import { routes } from "../../apis/routes";
import Capacitor from "../../utils/Capacitor";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, postCodeReport } from "../../apis";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import NewMessageModal, { INewMessageModal } from "./NewMessageModal";
import CodePusherModal, { ICodePusherModal } from "./CodePusherModal";
import { IRoomState, saveFocusState } from "../../redux/reducers/roomState";
import { IonIcon, IonLabel, IonButton, IonButtons, useIonModal } from "@ionic/react";
import { CLIENT_CODING_EVENTS, CLIENT_FOCUS_EVENTS } from "@com.xcodeclazz/socket-polling";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { codeOutline, chatbubbleEllipsesOutline, trashOutline, funnelOutline } from "ionicons/icons";
import { server_broadcast, server_clear_broadcast_history, server_clear_chat_history, server_clear_code_history, server_clear_kickout_history, server_clear_question_history, server_clear_raisehand_history, server_run_code_schedule, server_runcode } from "../../socket-host-emits";

export interface ICallToActionButtons {
  updateViewersCount: (count: number) => void;
}
  
const CallToActionButtons = forwardRef<ICallToActionButtons, {}>(
  (props, ref) => {
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      updateViewersCount: (count: number) => {},
    }));

    const roomState: IRoomState = useSelector((state: any) => state.roomState);

    const [presentMessageModal, dismissMessageModal] = useIonModal(NewMessageModal, { onDismiss: (data: INewMessageModal | null, role: string) => dismissMessageModal(data, role) });
    const [presentCodePusherModal, dismissCodePusherModal] = useIonModal(CodePusherModal, { onDismiss: (data: INewMessageModal | null, role: string) => dismissCodePusherModal(data, role) });

    function openMessageModal() {
      presentMessageModal({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail<INewMessageModal>>) => {
          if (ev.detail.role === "confirm") {
            if (ev.detail.data?.message) {
              server_broadcast({ body: ev.detail.data?.message });
            }
          }
        },
      });
    }
    
    function openCodePusherModal() {
      presentCodePusherModal({
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail<ICodePusherModal>>) => {
          if (ev.detail.role === "confirm") {
            server_run_code_schedule({
              code: ev.detail.data?.code || "",
              lang: ev.detail.data?.lang || "",
              input: ev.detail.data?.input || "",
              output: ev.detail.data?.output || "",
              schedule: ev.detail.data?.schedule || 0,
            });
          }
        },
      });
    }

    useEffect(() => {
      socketHost.on(CLIENT_FOCUS_EVENTS.ALL.YIELD_TOTAL_FOCUS, totalFocusListener);
      socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_CODE_OUTPUT_REPORT, codeOutputReportListener);
      socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_RUN_CODE_SCHEDULE_DONE, codeRunScheduleDoneListener);
      socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_PUSH_CODE_SCHEDULE_DONE, codePushScheduleDoneListener);
      return () => {
        socketHost.off(CLIENT_FOCUS_EVENTS.ALL.YIELD_TOTAL_FOCUS, totalFocusListener);
        socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_CODE_OUTPUT_REPORT, codeOutputReportListener);
        socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_RUN_CODE_SCHEDULE_DONE, codeRunScheduleDoneListener);
        socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_PUSH_CODE_SCHEDULE_DONE, codePushScheduleDoneListener);
      };
    });

    const codeOutputReportListener = (response: CLIENT_CODING_EVENTS.YIELD_CODE_OUTPUT_REPORT) => {
      postCodeReport(roomState.roomId, {
        roomId: response.roomId,
        code: response.code,
        completed: response.completed || false,
        createdAt: response.createdAt.toString(),
        updatedAt: response.updatedAt.toString(),
        output: response.output || "",
        input: response.input || "",
        lang: response.lang,
        metrics: {
          submitters: response.metrics.submitters,
          finished: response.metrics.finished,
          total_failed: response.metrics.total_failed,
          total_passed: response.metrics.total_passed,
        },
        seconds: response.seconds,
        id: response.id
      }, (_response) => {
        Capacitor.toast("Report has been sent to server", "long");
      }, (error) => {
        console.log(error);
        errorToast(error);
      });
    };
    const totalFocusListener = (response: CLIENT_FOCUS_EVENTS.YIELD_TOTAL_FOCUS) => dispatch(saveFocusState(response));
    const codeRunScheduleDoneListener = (response: CLIENT_CODING_EVENTS.YIELD_RUN_CODE_SCHEDULE_DONE) => { console.log(response); };
    const codePushScheduleDoneListener = (response: CLIENT_CODING_EVENTS.YIELD_PUSH_CODE_SCHEDULE_DONE) => server_runcode({ seconds: 30 });
    const clearRoom = () => {
      server_clear_chat_history();
      server_clear_code_history();
      server_clear_kickout_history();
      server_clear_question_history();
      server_clear_raisehand_history();
      server_clear_broadcast_history();
    };

    const openCodeReport = () => {
      if (roomState?.roomId) window.open(routes.GET_CODE_REPORT.replace(":id", roomState.roomId), "_blank");
    };

    return (
      <>
        <IonButtons>
          <IonButton color="primary" disabled>
            <IonLabel className="text-lg">Total: {roomState.user_count}</IonLabel>
          </IonButton>
          <IonButton color="primary" disabled>
            <IonLabel className="text-lg">Focus: {roomState.focus_state?.focus || 0}</IonLabel>
          </IonButton>
          <IonButton title="Broadcast New Message" color="primary" onClick={openMessageModal}>
            <IonIcon icon={chatbubbleEllipsesOutline} />
          </IonButton>
          <IonButton title="Schedule Code Push" color="primary" onClick={openCodePusherModal}>
            <IonIcon icon={codeOutline} />
          </IonButton>
          <IonButton title="Clear Everything" color="primary" onClick={clearRoom}>
            <IonIcon icon={trashOutline} />
          </IonButton>
          <IonButton title="Code Report" color="primary" onClick={openCodeReport}>
            <IonIcon icon={funnelOutline} size="small" slot="icon-only" />
          </IonButton>
          {/* https://discord.com/xcc-encrppted-string - for each new batch*/}
        </IonButtons>
      </>
    );
  }
);

export default CallToActionButtons;

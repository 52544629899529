import socketHost from "../socket-host";
import { useHistory } from "react-router";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from "react";
import { server_login } from "../socket-host-emits";
import { CLIENT_AUTH_EVENTS } from "@com.xcodeclazz/socket-polling";
import {
  IonRow,
  IonCol,
  IonPage,
  IonGrid,
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonButtons,
  IonSpinner,
  IonThumbnail,
} from "@ionic/react";

import "./Login.css";

const Login: React.FC<{}> = (props) => {
  const history = useHistory();
  const usernameRef = React.createRef<HTMLIonInputElement>();
  const passwordRef = React.createRef<HTMLIonInputElement>();
  const [loading, setLoading] = useState<boolean>(false);

  const onLoginHandler = () => {
    const username = usernameRef.current?.value?.toString();
    const password = passwordRef.current?.value?.toString();
    if (!username || !password) return;
    setLoading(true);
    server_login({ username, password });
  };

  const loginSucceedListener = () => {
    setLoading(false);
    setTimeout(() => history.replace("/lobby"), 1000);
  };

  const loginFailedListener = () => {
    setLoading(false);
    Capacitor.toast("Login Failed", "long");
  };

  useEffect(() => {
    socketHost.on(CLIENT_AUTH_EVENTS.HOST.YIELD_LOGIN_FAILED, loginFailedListener);
    socketHost.on(CLIENT_AUTH_EVENTS.HOST.YIELD_LOGIN_SUCCEED, loginSucceedListener);
    return () => {
      socketHost.off(CLIENT_AUTH_EVENTS.HOST.YIELD_LOGIN_FAILED, loginFailedListener);
      socketHost.off(CLIENT_AUTH_EVENTS.HOST.YIELD_LOGIN_SUCCEED, loginSucceedListener);
    };
  }, []);

  return (
    <IonPage>
      <Header themeBtn />
      <IonContent className="ion-padding">
        <section className="h-full w-full">
          <IonGrid className="h-full">
            <IonRow className="h-full">
              <IonCol size="12" sizeMd="6">
                <section className="h-full flex flex-col items-center justify-center ion-padding">
                  <IonThumbnail>
                    <img alt="avatar" src="/assets/icon/xcodeclazz.png" />
                  </IonThumbnail>
                  <h1 className="text-4xl md:text-5xl"><IonLabel color="secondary" className="font-bold">x</IonLabel>CodeClazz</h1>
                  <small className="text-center text-md md:text-lg">Learn To Code</small>
                </section>
              </IonCol>
              <IonCol size="12" sizeMd="6" className="flex justify-center md:items-center">
                <section className="w-full lg:w-2/3">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonInput ref={usernameRef} label="Username" labelPlacement="floating" fill="outline" placeholder="Your username" type="text" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonInput ref={passwordRef} label="Password" labelPlacement="floating" fill="outline" placeholder="*****" type="password" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonButtons className="w-full flex justify-end">
                          <IonButton fill="outline" onClick={onLoginHandler}>
                            Login{loading && <IonSpinner name="dots" />}
                          </IonButton>
                        </IonButtons>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Login;

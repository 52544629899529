import _ from "lodash";
import AceEditor from "react-ace";
import { routes } from "../apis/routes";
import socketHost from "../socket-host";
import Capacitor from "../utils/Capacitor";
import { useLocation } from "react-router";
import { playEchoBeepSound } from "../sfxs";
import Header from "../components/common/Header";
import { errorToast, postCodeReport } from "../apis";
import EditorState from "../utils/common/editor-state";
import { useDispatch, useSelector } from "react-redux";
import { getEditorTheme } from "../utils/common/helper";
import React, { useEffect, useRef, useState } from "react";
import SectionRaisehand from "../components/room/SectionRaisehand";
import { eyeOutline, funnelOutline, trashOutline } from "ionicons/icons";
import { saveRaisehandHistory } from "../redux/reducers/raisehandState";
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { IRoomState, saveRoomDetails, saveUserCount } from "../redux/reducers/roomState";
import { IonCol, IonRow, IonCard, IonPage, IonGrid, IonContent, IonItem, IonButtons, IonButton, IonIcon, IonTextarea } from "@ionic/react";
import { server_chat_history, server_clear_broadcast_history, server_clear_chat_history, server_clear_code_history, server_clear_kickout_history, server_clear_question_history, server_clear_raisehand_history, server_client_count, server_join, server_question_history, server_questions_templates, server_raisehand_history, server_runcode } from "../socket-host-emits";
import {
  CLIENT_ROOM_EVENTS,
  CLIENT_CODING_EVENTS,
  CLIENT_RAISEHAND_EVENTS,
} from "@com.xcodeclazz/socket-polling";

const EditorRoom: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const editorState = new EditorState();
  const inputRef = useRef<HTMLIonTextareaElement>(null);
  const outputRef = useRef<HTMLIonTextareaElement>(null);
  const theme_state: string = useSelector((state: any) => state.uiState.theme);

  const roomId = new URLSearchParams(location.search)?.get('roomId');
  const name = new URLSearchParams(location.search)?.get('name');
  const lang = new URLSearchParams(location.search)?.get('lang');

  const roomState: IRoomState = useSelector((state: any) => state.roomState);

  const [fontSize, ] = useState<number>(18);
  const [showGutter, ] = useState<boolean>(true);
  const [second, setSecond] = useState<number>(30);
  const [source, setSource] = useState<string>("");
  const [selectedLang, setSelectedLang] = useState<ILanguage>(lang as ILanguage || ILanguage.Python);

  useEffect(() => {}, [theme_state]);
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  // const handleSubmit = (proceed: boolean, reason: string) => {
  //   if (proceed) {
  //     const data = {
  //       code: source,
  //       schedule: second,
  //       lang: selectedLang,
  //       input: inputRef.current?.value?.toString() || "",
  //       output: outputRef.current?.value?.toString() || "",
  //     };
  //     onDismiss(data, reason);
  //   } else {
  //     onDismiss(null, reason);
  //   }
  // };

  const counterListener = (response: CLIENT_ROOM_EVENTS.YIELD_TOTAL_CLIENT_COUNT) => dispatch(saveUserCount({ count: response.count }));
  const newRaisehandListener = (response: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND) => {
    dispatch(saveRaisehandHistory(response));
    playEchoBeepSound();
  }

  const openCodeReport = () => { if (roomState?.roomId) window.open(routes.GET_CODE_REPORT.replace(":id", roomState.roomId), "_blank"); };
  const codeOutputReportListener = (response: CLIENT_CODING_EVENTS.YIELD_CODE_OUTPUT_REPORT) => {
    postCodeReport(roomState.roomId, {
      roomId: response.roomId,
      code: response.code,
      completed: response.completed || false,
      createdAt: response.createdAt.toString(),
      updatedAt: response.updatedAt.toString(),
      output: response.output || "",
      input: response.input || "",
      lang: response.lang,
      metrics: {
        submitters: response.metrics.submitters,
        finished: response.metrics.finished,
        total_failed: response.metrics.total_failed,
        total_passed: response.metrics.total_passed,
      },
      seconds: response.seconds,
      id: response.id
    }, (_response) => {
      console.log(_response.data);
      Capacitor.toast("Report has been sent to server", "long");
    }, (error) => {
      console.log(error);
      errorToast(error);
    });
  };

  const codeRunScheduleDoneListener = (response: CLIENT_CODING_EVENTS.YIELD_RUN_CODE_SCHEDULE_DONE) => { console.log(response); };
  const codePushScheduleDoneListener = (response: CLIENT_CODING_EVENTS.YIELD_PUSH_CODE_SCHEDULE_DONE) => server_runcode({ seconds: 30 });
  const clearRoom = () => {
    server_clear_chat_history();
    server_clear_code_history();
    server_clear_kickout_history();
    server_clear_question_history();
    server_clear_raisehand_history();
    server_clear_broadcast_history();
  };

  const userJoinListener = () => {
    server_raisehand_history();
    server_question_history();
    server_client_count();
    server_chat_history();
  };

  useEffect(() => {
    if (!roomId || !name || !lang) {}
    else server_join({ name, roomId });
    dispatch(saveRoomDetails({ name, roomId }));
    server_questions_templates();    
    socketHost.on(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
    socketHost.on(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
    socketHost.on(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
    socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_CODE_OUTPUT_REPORT, codeOutputReportListener);
    socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_RUN_CODE_SCHEDULE_DONE, codeRunScheduleDoneListener);
    socketHost.on(CLIENT_CODING_EVENTS.HOST.YIELD_PUSH_CODE_SCHEDULE_DONE, codePushScheduleDoneListener);
    return () => {
      socketHost.off(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
      socketHost.off(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
      socketHost.off(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
      socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_CODE_OUTPUT_REPORT, codeOutputReportListener);
      socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_RUN_CODE_SCHEDULE_DONE, codeRunScheduleDoneListener);
      socketHost.off(CLIENT_CODING_EVENTS.HOST.YIELD_PUSH_CODE_SCHEDULE_DONE, codePushScheduleDoneListener);
    };
  }, []);

  return (
    <IonPage>
      <Header themeBtn />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-hidden">
          <IonRow className="h-full">
            <IonCol size="12" sizeLg="8" className="h-full">
              <IonGrid className="h-full">
                <IonRow>
                  <IonCol>
                    <IonItem><IonTextarea ref={inputRef} placeholder="Please provide input" spellCheck={false} className="text-lg" /></IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem><IonTextarea ref={outputRef} placeholder="Please provide output" spellCheck={false} className="text-lg" /></IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className="h-full">
                  <IonCol>
                    <AceEditor
                      name={`ID-${Math.random()}`}
                      mode={editorState.getMode(selectedLang)}
                      width="100"
                      theme={getEditorTheme()}
                      fontSize={fontSize}
                      highlightActiveLine={true}
                      showPrintMargin={true}
                      readOnly={false}
                      showGutter={showGutter}
                      wrapEnabled={false}
                      editorProps={{ $blockScrolling: true }}
                      onLoad={(editor) => { editor.renderer.setPadding(10); }}
                      style={{ height: "90%", backgroundColor: "transparent" }}
                      // value={``}
                      // debounceChangePeriod={1000}
                      onChange={setSource}
                      setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        showLineNumbers: true,
                        tabSize: 2,
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeLg="4" className="h-full overflow-hidden">
              <IonCard className="h-full w-full ion-no-margin rounded-lg">
                <IonItem lines="none">
                  <IonButtons slot="start">
                    <IonButton title="Total Members" color="primary">
                      <IonIcon icon={eyeOutline} slot="end" />
                      {roomState.user_count}
                    </IonButton>
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonButton title="Clear Everything" color="primary" onClick={clearRoom}>
                      <IonIcon icon={trashOutline} slot="icon-only" />
                    </IonButton>
                    <IonButton title="Code Report" color="primary" onClick={openCodeReport}>
                      <IonIcon icon={funnelOutline} slot="icon-only" />
                    </IonButton>
                  </IonButtons>
                </IonItem>
                <SectionRaisehand />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EditorRoom;

import { IonToast } from "@ionic/react";
import { forwardRef, useImperativeHandle, useState } from "react";

export interface IToastMessageCta {
  open: (header: string, message: string) => void;
  close: () => void;
}

const ToastMessageCta = forwardRef<
  IToastMessageCta,
  {
    onOkClicked: () => void;
    onDismissClicked: () => void;
  }
>((props, ref) => {
  const [value, setValue] = useState<{ header: string; message: string }>({
    header: "Information",
    message: "Test report has been sent",
  });
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (header: string, message: string) => {
      setValue({ header, message });
      setOpen(true);
    },
    close: () => setOpen(false),
  }));

  return (
    <IonToast
      message={value.message}
      position={"top"}
      color={"primary"}
      header={value.header}
      duration={5000}
      isOpen={open}
      buttons={[
        {
          text: "Ok",
          role: "info",
          handler: () => {
            setOpen(false);
            props.onOkClicked();
          },
        },
        {
          text: "Dismiss",
          role: "cancel",
          handler: () => {
            setOpen(false);
            props.onDismissClicked();
          },
        },
      ]}
      onDidDismiss={(e: CustomEvent) => {
        setOpen(false);
        props.onDismissClicked();
      }}
    ></IonToast>
  );
});

export default ToastMessageCta;

import { getEditorTheme } from "../../utils/common/helper";
import { closeOutline } from "ionicons/icons";
import { useRef, useState } from "react";
import TimerButton from "../TimerButton";
import AceEditor from "react-ace";
import {
  IonPage,
  IonItem,
  IonCard,
  IonIcon,
  IonTitle,
  IonLabel,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonItemDivider,
} from "@ionic/react";

export interface IInputQuestionTemplateModal {
  answer: string;
  second: string;
  title: string;
  code?: string;
  input: string;
  text: string;
}

const InputQuestionTemplateModal = ({
  onDismiss,
}: {
  onDismiss: (data?: IInputQuestionTemplateModal | null, role?: string) => void;
}) => {
  const inputRef = useRef<HTMLIonTextareaElement>(null);
  const titleRef = useRef<HTMLIonInputElement>(null);
  const textRef = useRef<HTMLIonInputElement>(null);
  const [source, setSource] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [second, setSecond] = useState<number>(30);

  const handleSubmit = (proceed: boolean, reason: string) => {
    if (proceed) {
      const data: IInputQuestionTemplateModal = {
        input: inputRef.current?.value?.toString() || '',
        title: titleRef.current?.value?.toString() || '',
        text: textRef.current?.value?.toString() || '',
        answer: answer,
        code: source,
        second: String(second),
      };
      onDismiss(data, reason);
    } else {
      onDismiss(null, reason);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => handleSubmit(false, "cancel")}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Input</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSubmit(true, "confirm")}>
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonInput ref={titleRef} label="Title" placeholder="Any Guess" />
        </IonItem>
        <IonItem>
          <IonInput
            ref={textRef}
            label="Text"
            placeholder="What would be the output of this code?"
          />
        </IonItem>
        <IonCard className="h-40 p-2">
          <AceEditor
            name={`ID-${Math.random()}`}
            theme={getEditorTheme()}
            mode={"text"}
            width="100"
            fontSize={16}
            highlightActiveLine={false}
            showPrintMargin={true}
            readOnly={false}
            showGutter={false}
            wrapEnabled={false}
            style={{ height: "100%" }}
            placeholder="Code Here [Optional]"
            editorProps={{ $blockScrolling: true }}
            onLoad={(editor) => { editor.renderer.setPadding(10) }}
            // value={``}
            // debounceChangePeriod={1000}
            onChange={setSource}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
          />
        </IonCard>
        <IonCard className="h-40 p-2">
          <AceEditor
            name={`ID-${Math.random()}`}
            theme={getEditorTheme()}
            mode={"text"}
            width="100"
            fontSize={16}
            highlightActiveLine={false}
            showPrintMargin={true}
            readOnly={false}
            showGutter={false}
            wrapEnabled={false}
            style={{ height: "100%" }}
            placeholder="Answer"
            editorProps={{ $blockScrolling: true }}
            onLoad={(editor) => { editor.renderer.setPadding(10) }}
            // value={``}
            // debounceChangePeriod={1000}
            onChange={setAnswer}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
          />
        </IonCard>
        <IonItemDivider />
        <IonItem>
          <IonLabel>Seconds</IonLabel>
          <IonButtons slot="end">
            <TimerButton text="30" active={second === 30} onClick={() => setSecond(30)} />
            <TimerButton text="60" active={second === 60} onClick={() => setSecond(60)} />
            <TimerButton text="90" active={second === 90} onClick={() => setSecond(90)} />
            <TimerButton text="120" active={second === 120} onClick={() => setSecond(120)} />
            <TimerButton text="300" active={second === 300} onClick={() => setSecond(300)} />
            <TimerButton text="600" active={second === 600} onClick={() => setSecond(600)} />
            <TimerButton text="1200" active={second === 1200} onClick={() => setSecond(1200)} />
          </IonButtons>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default InputQuestionTemplateModal;

import _ from "lodash";
import { IonCol, IonRow } from "@ionic/react";
import { forwardRef, useImperativeHandle } from "react";
import { IQuestionTemplate } from "../../apis/definations";
import { EQuestionCategory } from "@com.xcodeclazz/socket-polling";
import McqQuestionTemplate from "./question-templates/McqQuestionTemplate";
import InputQuestionTemplate from "./question-templates/InputQuestionTemplate";
import ChoiceQuestionTemplate from "./question-templates/ChoiceQuestionTemplate";

export interface IQuestionsGrid {}

const QuestionsGrid = forwardRef<IQuestionsGrid, 
{
  questionsTemplates: any[],
}
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  return (
    <IonRow>
      {_.map(props.questionsTemplates, (el: IQuestionTemplate, i: number) => (
        <IonCol size="12" sizeSm="6" sizeMd="4" className="h-min" key={i}>
          {el.category == EQuestionCategory.Mcq && <McqQuestionTemplate questionTemplate={el} />}
          {el.category == EQuestionCategory.Input && <InputQuestionTemplate questionTemplate={el} />}
          {el.category == EQuestionCategory.Choice && <ChoiceQuestionTemplate questionTemplate={el} />}
        </IonCol>
      ))}
    </IonRow>
  );
});

export default QuestionsGrid;

export enum ESegments {
  RAISEHAND = "raisehand",
  HISTORY = "history",
  CHATS = "chats",
}

export type TSegments = {
  RAISEHAND: "raisehand";
  HISTORY: "history";
  CHATS: "chats";
};

export interface IQuestionTemplate {
  qid: string;
  text: string;
  title: string;
  answer: string;
  category: string;
  seconds: { text: number; active: boolean }[];

  radios?: { text: number; checked: boolean }[];
  input?: { placeholder: string; value: string };
  checkboxes?: { text: string; checked: boolean }[];
}
import { useSelector } from "react-redux";
import { ESegments } from "../../apis/definations";
import React, { useEffect, useState } from "react";
import { IChatState, IQuestionState, IRaisehandState } from "@com.xcodeclazz/socket-polling";
import {
  IonCard,
  IonIcon,
  IonText,
  IonSegment,
  SegmentValue,
  IonSegmentButton,
} from "@ionic/react";
import {
  listOutline,
  handRightOutline,
  chatbubbleOutline,
} from "ionicons/icons";

const SectionSegments: React.FC<{
  onSegmentChange: (segmentName: SegmentValue | undefined) => void;
  currentSegment: ESegments;
}> = (props) => {
  const [currentSegment, setCurrentSegment] = useState<ESegments>(props.currentSegment);

  const chats: IChatState[] = useSelector((state: any) => state.chatState.history);
  const questions: IQuestionState[] = useSelector((state: any) => state.questionState.history);
  const raisehands: IRaisehandState[] = useSelector((state: any) => state.raisehandState.history);

  useEffect(() => {}, [raisehands, questions]);

  const onSegmentChangeHandler = (value: SegmentValue | undefined) => {
    props.onSegmentChange(value);
    setCurrentSegment(value as ESegments);
  };

  return (
    <IonCard className="ion-no-margin rounded-t-xl rounded-b-none mb-2">
      <IonSegment
        scrollable
        value={currentSegment}
        onIonChange={(e) => onSegmentChangeHandler(e.target.value)}
      >
        <IonSegmentButton
          className="space-x-2"
          value={ESegments.HISTORY}
          layout="icon-start"
        >
          <IonIcon icon={listOutline}></IonIcon>
          <IonText>{questions.length}</IonText>
        </IonSegmentButton>
        <IonSegmentButton
          className="space-x-2"
          value={ESegments.RAISEHAND}
          layout="icon-start"
        >
          <IonIcon icon={handRightOutline}></IonIcon>
          <IonText>{raisehands.length}</IonText>
        </IonSegmentButton>
        <IonSegmentButton
          className="space-x-2"
          value={ESegments.CHATS}
          layout="icon-start"
        >
          <IonIcon icon={chatbubbleOutline}></IonIcon>
          <IonText>{chats.length}</IonText>
        </IonSegmentButton>
      </IonSegment>
    </IonCard>
  );
};

export default SectionSegments;

import _ from "lodash";
import { useSelector } from "react-redux";
import { getRandomColorName } from "../../utils/common/helper";
import { arrowRedoOutline, codeOutline } from "ionicons/icons";
import React, { forwardRef, useImperativeHandle } from "react";
import ToastMessageCta, { IToastMessageCta } from "../ToastMessageCta";
import { CLIENT_RAISEHAND_EVENTS } from "@com.xcodeclazz/socket-polling";
import {
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonItem,
  IonList,
  IonGrid,
  IonButton,
  IonButtons,
  IonTextarea,
} from "@ionic/react";

export interface ISectionRaisehand {}

const getLineCodeLinesHeight = (code: string) => code ? code.split("\n").length : 3;
const SectionRaisehand = forwardRef<ISectionRaisehand, {}>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  const raisehands: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND_HISTORY = useSelector((state: any) => state.raisehandState);
  const toastMsgRef = React.createRef<IToastMessageCta>();
  const publishReport = () => {
    console.log("send the data to supabase, and the report will display @ report.xcodeclazz.com");
  };

  return (
    <>
      <IonList className="h-full overflow-scroll pb-20" lines="none">
        {_.map(raisehands.history, (el, idx) => (
          <IonItem key={idx} button>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonGrid>
                    <IonRow>
                      <div>
                        <IonText color={getRandomColorName()}>{el.name}</IonText>
                        <br />
                        <IonText className="font-bold text-xs">{el.body}</IonText>
                      </div>
                    </IonRow>
                    <IonRow className="text-xs">{el.code && <IonTextarea disabled rows={getLineCodeLinesHeight(el.code)} value={el.code} />}</IonRow>
                    <IonRow className="text-xs">{el.output && <IonTextarea disabled rows={getLineCodeLinesHeight(el.output)} value={el.output} />}</IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonList>
      <ToastMessageCta
        onDismissClicked={() => {}}
        onOkClicked={() => {}}
        ref={toastMsgRef}
      />
    </>
  );
});

export default SectionRaisehand;

import ToastMessageCta, { IToastMessageCta } from "../ToastMessageCta";
import { arrowRedoOutline, footstepsOutline } from "ionicons/icons";
import { CLIENT_CHAT_EVENTS } from "@com.xcodeclazz/socket-polling";
import React, { forwardRef, useImperativeHandle } from "react";
import { getRandomColorName } from "../../utils/common/helper";
import { removeChat } from "../../redux/reducers/chatState";
import { server_kickout } from "../../socket-host-emits";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  IonCol,
  IonRow,
  IonText,
  IonIcon,
  IonItem,
  IonList,
  IonGrid,
  IonButton,
  IonButtons,
} from "@ionic/react";

export interface ISectionChats {}

const SectionChats = forwardRef<ISectionChats, {}>((props, ref) => {
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({}));

  const toastMsgRef = React.createRef<IToastMessageCta>();
  const chats: CLIENT_CHAT_EVENTS.YIELD_CHAT_HISTORY = useSelector((state: any) => state.chatState);
  const kickoutHandler = (user: CLIENT_CHAT_EVENTS.YIELD_CHAT_MESSAGE) => {
    server_kickout({ name: user.name, target: user.sid });
    dispatch(removeChat(user));
  };

  return (
    <>
      <IonList className="h-full overflow-scroll pb-20" lines="none">
        {_.map(chats.history, (el, idx) => (
          <IonItem key={idx} button>
            <IonGrid>
              <IonRow>
                <IonCol size="10">
                  <IonGrid>
                    <IonRow>
                      <div>
                        <IonText color={getRandomColorName()}>{el.name}</IonText>{" "}
                        <br />
                        <IonText className="font-bold text-xs">{el.body}</IonText>
                      </div>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol size="2" className="flex flex-col justify-start items-end">
                  <IonButtons>
                    <IonButton>
                      <IonIcon title="reply" size="small" icon={arrowRedoOutline} />
                    </IonButton>
                    <IonButton onClick={e => kickoutHandler(el)}>
                      <IonIcon title="kickout" size="small" icon={footstepsOutline} />
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonList>
      <ToastMessageCta
        onDismissClicked={() => {}}
        onOkClicked={() => {}}
        ref={toastMsgRef}
      />
    </>
  );
});

export default SectionChats;

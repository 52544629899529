import { configureStore } from "@reduxjs/toolkit";
import raisehandState from "./reducers/raisehandState";
import counterReducer from "./reducers/counterSlice";
import questionState from "./reducers/questionState";
import userState from "./reducers/userState";
import chatState from "./reducers/chatState";
import roomState from "./reducers/roomState";
import uiState from "./reducers/uiState";

export default configureStore({
  reducer: {
    uiState: uiState,
    userState: userState,
    roomState: roomState,
    chatState: chatState,
    questionState: questionState,
    raisehandState: raisehandState,
    counterReducer: counterReducer,
  },
});

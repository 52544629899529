import socketHost from "./socket-host";
import {
  IRoomPayload,
  ILoginPayload,
  ICodeRunPayload,
  IKickoutPayload,
  IQuestionPayload,
  IBroadcastPayload,
  SERVER_ROOM_EVENTS,
  SERVER_CHAT_EVENTS,
  SERVER_AUTH_EVENTS,
  SERVER_CODING_EVENTS,
  SERVER_KICKOUT_EVENTS,
  SERVER_QUESTION_EVENTS,
  ICodeRunSchedulePayload,
  SERVER_RAISEHAND_EVENTS,
  SERVER_BROADCAST_EVENTS,
  ICodePushSchedulePayload,
} from "@com.xcodeclazz/socket-polling";

export const server_logout = () => {
  socketHost.emit(SERVER_AUTH_EVENTS.HOST.HOST_LOGOUT);
};

export const server_login = (payload: ILoginPayload) => {
  socketHost.emit(SERVER_AUTH_EVENTS.HOST.HOST_LOGIN, payload);
};

export const server_runcode = (payload: ICodeRunPayload) => {
  socketHost.emit(SERVER_CODING_EVENTS.HOST.HOST_RUN_CODE, payload);
};

export const server_clear_code_history = () => {
  socketHost.emit(SERVER_CODING_EVENTS.HOST.HOST_CLEAR_CODE_HISTORY, {});
};

export const server_push_code_schedule = (payload: ICodePushSchedulePayload) => {
  socketHost.emit(SERVER_CODING_EVENTS.HOST.HOST_PUSH_CODE_SCHEDULE, payload);
};

export const server_run_code_schedule = (payload: ICodeRunSchedulePayload) => {
  socketHost.emit(SERVER_CODING_EVENTS.HOST.HOST_RUN_CODE_SCHEDULE, payload);
};

export const server_kickout = (payload: IKickoutPayload) => {
  socketHost.emit(SERVER_KICKOUT_EVENTS.HOST.HOST_KICKOUT, payload);
};

export const server_clear_kickout_history = () => {
  socketHost.emit(SERVER_KICKOUT_EVENTS.HOST.HOST_CLEAR_KICKOUT_HISTORY, {});
};

export const server_broadcast = (payload: IBroadcastPayload) => {
  socketHost.emit(SERVER_BROADCAST_EVENTS.HOST.HOST_BROADCAST, payload);
};

export const server_clear_broadcast_history = () => {
  socketHost.emit(SERVER_BROADCAST_EVENTS.HOST.HOST_CLEAR_BROADCAST_HISTORY, {});
};

export const server_questioned = (payload: IQuestionPayload) => {
  socketHost.emit(SERVER_QUESTION_EVENTS.HOST.HOST_QUESTIONED, payload);
};

export const server_join = (payload: IRoomPayload) => {
  // socketHost.timeout(timeout).emit(SERVER_ROOM_EVENTS.HOST.HOST_JOIN, payload);
  socketHost.emit(SERVER_ROOM_EVENTS.HOST.HOST_JOIN, payload);
};

export const server_cancelled = () => {
  socketHost.emit(SERVER_QUESTION_EVENTS.HOST.HOST_CANCELLED, {});
};

export const server_chat_history = () => {
  socketHost.emit(SERVER_CHAT_EVENTS.ALL.CLIENT_CHAT_HISTORY, {});
};

export const server_clear_chat_history = () => {
  socketHost.emit(SERVER_CHAT_EVENTS.HOST.HOST_CLEAR_CHAT_HISTORY, {});
};

export const server_question_history = () => {
  socketHost.emit(SERVER_QUESTION_EVENTS.ALL.CLIENT_QUESTION_HISTORY, {});
};

export const server_clear_question_history = () => {
  socketHost.emit(SERVER_QUESTION_EVENTS.HOST.HOST_CLEAR_QUESTION_HISTORY, {});
};

export const server_questions_templates = () => {
  socketHost.emit(SERVER_QUESTION_EVENTS.HOST.HOST_GET_QUESTIONS_TEMPLATES, {});
};

export const server_raisehand_history = () => {
  socketHost.emit(SERVER_RAISEHAND_EVENTS.ALL.CLIENT_RAISEHAND_HISTORY, {});
};

export const server_clear_raisehand_history = () => {
  socketHost.emit(SERVER_RAISEHAND_EVENTS.HOST.HOST_CLEAR_RAISEHAND_HISTORY, {});
};

export const server_client_count = () => {
  socketHost.emit(SERVER_ROOM_EVENTS.ALL.CLIENT_TOTAL_CLIENT_COUNT, {});
};

import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import socketHost from "../../socket-host";
import React, { useEffect, useState } from "react";
import Countdown, { ICountdown } from "./Countdown";
import { server_logout } from "../../socket-host-emits";
import { setTheme } from "../../redux/reducers/uiState";
import TokenClass from "../../utils/common/token-state";
import TimerClass from "../../utils/common/timer-state";
import { exitOutline, moonOutline } from "ionicons/icons";
import { isDark, toggleDark } from "../../utils/common/helper";
import { CLIENT_TIMER_EVENTS } from "@com.xcodeclazz/socket-polling";
import { PREFERENCE_KEYS, setPreference } from "../../utils/common/cache";
import {
  IonIcon,
  IonTitle,
  IonButton,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";

const Header: React.FC<{
  logoutBtn?: boolean;
  themeBtn?: boolean;
  backBtn?: string;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenState = new TokenClass();
  const timerState = new TimerClass();
  const countdownRef = React.createRef<ICountdown>();
  const [running, setRunning] = useState<boolean>(false);
  const [timer, setTimer] = useState<CLIENT_TIMER_EVENTS.YIELD_TIMER_START>({ iat: Date.now(), title: "?" });

  const logout = () => {
    server_logout();
    setTimeout(() => history.replace("/"), 1000);
  };

  useEffect(() => {
    const timer = timerState.getTimer();
    if (timerState.isPrevTimeLeft()) {
      setRunning(true);
      setTimer({ title: timer?.title || "?", iat: timer?.iat || Date.now() });
    }
  }, []);

  const timerStartListener = (response: CLIENT_TIMER_EVENTS.YIELD_TIMER_START) => {
    timerState.saveTimer(response);
    setRunning(true);
    setTimer(response);
  };

  const timerStopListener = (response: CLIENT_TIMER_EVENTS.YIELD_TIMER_STOP) => {
    timerState.saveTimer({ iat: new Date(), title: "?" });
    setRunning(false);
  };

  useEffect(() => {
    socketHost.on(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_START, timerStartListener);
    socketHost.on(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_STOP, timerStopListener);
    return () => {
      socketHost.off(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_START, timerStartListener);
      socketHost.off(CLIENT_TIMER_EVENTS.ALL.YIELD_TIMER_STOP, timerStopListener);
    };
  }, [countdownRef]);

  return (
    <IonHeader>
      <IonToolbar>
        {props.backBtn && (
          <IonButtons slot="start" className="md:hidden">
            <IonBackButton defaultHref={props.backBtn}></IonBackButton>
          </IonButtons>
        )}
        <IonTitle>
          <strong className="text-yellow-500">x</strong>
          <strong className="cursor-pointer" onClick={(e) => history.push("/")}>CodeClazz</strong>
        </IonTitle>
        <IonButtons slot="end">
          {running && <Countdown futureTime={new Date(timer.iat)} reason={timer.title} onTimeUp={() => setRunning(false)} />}
          {!tokenState.isTokenExpired() && props.logoutBtn && (
            <IonButton title="logout" onClick={logout}>
              <IonIcon slot="icon-only" icon={exitOutline} />
            </IonButton>
          )}
          {props.themeBtn && (<IonButton title="theme" onClick={e => { toggleDark(); setPreference(PREFERENCE_KEYS.DARK_MODE, `${isDark()}`); dispatch(setTheme({ theme: `${isDark()}` })) }}>
              <IonIcon slot="icon-only" icon={moonOutline} />
            </IonButton>)}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;

import { CLIENT_AUTH_EVENTS } from "@com.xcodeclazz/socket-polling";

export default class TokenClass {
  saveToken(token: CLIENT_AUTH_EVENTS.YIELD_TOKEN): void { 
    this.save("lock", JSON.stringify(token));
  }
  getToken(): CLIENT_AUTH_EVENTS.YIELD_TOKEN | null {
    const lock = this.get("lock");
    return lock ? JSON.parse(lock) : null;
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    const cts = Math.floor(Date.now() / 1000);
    return token?.iat?.exp ? cts >= token?.iat?.exp : true;
  }

  get(key: string): string | null {
    if (localStorage.getItem(key)) return localStorage.getItem(key);
    return null;
  }

  save(key: string, value: any): void {
    if (value == null) return;
    localStorage.setItem(key, value);
  }

  delete = (key: string) => localStorage.removeItem(key);
  clean = () => localStorage.clear();
}

import { forwardRef, useImperativeHandle } from "react";
import { IonButton, IonText } from "@ionic/react";

export interface ITimerButton {}

const TimerButton = forwardRef<
  ITimerButton,
  {
    text: string;
    active?: boolean;
    onClick?: () => void;
  }
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));

  return (
    <IonButton
      onClick={props.onClick}
      className="font-bold w-min rounded-full"
      fill={props.active ? "outline" : "clear"}
      color={props.active ? "primary" : ""}
    >
      <IonText>{props.text}</IonText>
    </IonButton>
  );
});

export default TimerButton;

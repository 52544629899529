import _ from "lodash";
import socketHost from "../socket-host";
import { useLocation } from "react-router";
import { playEchoBeepSound } from "../sfxs";
import { ESegments } from "../apis/definations";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveChatHistories, saveChatHistory } from "../redux/reducers/chatState";
import { IRoomState, saveRoomDetails, saveUserCount } from "../redux/reducers/roomState";
import { saveRaisehandHistories, saveRaisehandHistory } from "../redux/reducers/raisehandState";
import { IonCol, IonRow, IonCard, IonPage, IonGrid, IonContent, IonSkeletonText } from "@ionic/react";
import { saveQuestionHistories, saveQuestionHistory, saveQuestionsTemplates } from "../redux/reducers/questionState";
import { server_chat_history, server_client_count, server_join, server_question_history, server_questions_templates, server_raisehand_history } from "../socket-host-emits";
import {
  CLIENT_CHAT_EVENTS,
  CLIENT_ROOM_EVENTS,
  CLIENT_QUESTION_EVENTS,
  CLIENT_RAISEHAND_EVENTS,
} from "@com.xcodeclazz/socket-polling";

import CallToActionButtons from "../components/room/CallToActionButtons";
import SectionRaisehand from "../components/room/SectionRaisehand";
import SectionSegments from "../components/room/SectionSegments";
import SectionHistory from "../components/room/SectionHistory";
import QuestionsGrid from "../components/room/QuestionsGrid";
import SectionChats from "../components/room/SectionChats";
import Header from "../components/common/Header";

const Room: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const roomState: IRoomState = useSelector((state: any) => state.roomState);
  const questionsTemplates: any[] = useSelector((state: any) => state.questionState.templates);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return (event.returnValue = "Are you sure you want to leave?");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const roomId = new URLSearchParams(location.search)?.get('roomId');
  const name = new URLSearchParams(location.search)?.get('name');
  const lang = new URLSearchParams(location.search)?.get('lang');

  const [currentSegment, setCurrentSegment] = useState<ESegments>(
    ESegments.RAISEHAND
  );

  const counterListener = (response: CLIENT_ROOM_EVENTS.YIELD_TOTAL_CLIENT_COUNT) => dispatch(saveUserCount({ count: response.count }));
  
  const newChatListener = (response: CLIENT_CHAT_EVENTS.YIELD_CHAT_MESSAGE) => dispatch(saveChatHistory(response));
  const chatsHistoryListener = (response: CLIENT_CHAT_EVENTS.YIELD_CHAT_HISTORY) => dispatch(saveChatHistories(response));
  
  const newRaisehandListener = (response: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND) => {
    dispatch(saveRaisehandHistory(response));
    playEchoBeepSound();
  }
  const raisehandHistoryListener = (response: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND_HISTORY) => dispatch(saveRaisehandHistories(response));

  const questionReportListener = (response: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_REPORT) => dispatch(saveQuestionHistory(response));
  const questionHistoryListener = (response: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_HISTORY) => dispatch(saveQuestionHistories(response));
  const questionsTemplatesListener = (response: any) => dispatch(saveQuestionsTemplates(response.templates));

  const userJoinListener = () => {
    server_raisehand_history();
    server_question_history();
    server_client_count();
    server_chat_history();
  };

  useEffect(() => {
    if (!roomId || !name || !lang) {}
    else server_join({ name, roomId });
    dispatch(saveRoomDetails({ name, roomId }));
    server_questions_templates();    
    socketHost.on(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
    socketHost.on(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_MESSAGE, newChatListener);
    socketHost.on(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_HISTORY, chatsHistoryListener);
    socketHost.on(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
    socketHost.on(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_REPORT, questionReportListener);
    socketHost.on(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_HISTORY, questionHistoryListener);
    socketHost.on(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
    socketHost.on(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND_HISTORY, raisehandHistoryListener);
    socketHost.on(CLIENT_QUESTION_EVENTS.HOST.YIELD_DELETED_QUESTIONS_TEMPLATES, questionsTemplatesListener);
    return () => {
      socketHost.off(CLIENT_ROOM_EVENTS.ALL.YIELD_JOIN, userJoinListener);
      socketHost.off(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_MESSAGE, newChatListener);
      socketHost.off(CLIENT_CHAT_EVENTS.ALL.YIELD_CHAT_HISTORY, chatsHistoryListener);
      socketHost.off(CLIENT_ROOM_EVENTS.ALL.YIELD_TOTAL_CLIENT_COUNT, counterListener);
      socketHost.off(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_REPORT, questionReportListener);
      socketHost.off(CLIENT_QUESTION_EVENTS.ALL.YIELD_QUESTION_HISTORY, questionHistoryListener);
      socketHost.off(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND, newRaisehandListener);
      socketHost.off(CLIENT_RAISEHAND_EVENTS.ALL.YIELD_RAISEHAND_HISTORY, raisehandHistoryListener);
      socketHost.off(CLIENT_QUESTION_EVENTS.HOST.YIELD_DELETED_QUESTIONS_TEMPLATES, questionsTemplatesListener);
    };
  }, []);

  return (
    <IonPage>
      <Header themeBtn />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-scroll">
          <IonRow className="h-full">
            <IonCol size="12" sizeLg="8" className="h-full">
              <IonGrid className="h-full">
                <IonRow className="h-min">
                  <IonCol size="12" sizeSm="6">
                    <div className="px-3">
                      <h1 className="capitalize font-bold">{roomState.name}</h1>
                      <small className="text-lg">#{roomState.roomId}</small>
                    </div>
                  </IonCol>
                  <IonCol size="12" sizeSm="6" className="flex flex-col justify-center md:items-end">
                    <CallToActionButtons />
                  </IonCol>
                </IonRow>
                <IonRow className="h-4/5 overflow-scroll">
                  {questionsTemplates.length == 0 && <IonSkeletonText animated />}
                  <QuestionsGrid questionsTemplates={questionsTemplates} />
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeLg="4" className="h-full overflow-hidden">
              <IonCard className="h-full w-full ion-no-margin rounded-lg">
                <SectionSegments currentSegment={currentSegment} onSegmentChange={(e) => setCurrentSegment(e?.toString() as ESegments) } />
                {currentSegment === ESegments.RAISEHAND && <SectionRaisehand />}
                {currentSegment === ESegments.HISTORY && <SectionHistory />}
                {currentSegment === ESegments.CHATS && <SectionChats />}
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Room;

import { CLIENT_RAISEHAND_EVENTS, RaisehandAttrs } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const raisehandState = createSlice({
  name: "raisehandState",
  initialState: {
    history: [] as RaisehandAttrs[],
  },
  reducers: {
    saveRaisehandHistory: (state, action) => {
      const raisehand: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND = action.payload;
      state.history.unshift(raisehand);
    },
    saveRaisehandHistories: (state, action) => {
      const data: CLIENT_RAISEHAND_EVENTS.YIELD_RAISEHAND_HISTORY = action.payload;
      if (data.history) state.history = _.reverse(_.cloneDeep(data.history));
    },
    clearRaisehandHistories: (state) => {
      state.history = [];
    },
  },
});

export const {
  saveRaisehandHistory,
  saveRaisehandHistories,
  clearRaisehandHistories,
} = raisehandState.actions;

export default raisehandState.reducer;

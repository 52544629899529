import _ from "lodash";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PrivateRoute from "./components/PrivateRoute";
import NonPrivateRoute from "./components/NonPrivateRoute";
import { IonReactRouter } from "@ionic/react-router";

import { enterOutline, pieChartOutline } from 'ionicons/icons';
import { IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonSpinner, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from '@ionic/react';

import Room from "./pages/Room";
import Lobby from "./pages/Lobby";
import Login from "./pages/Login";
import EditorRoom from "./pages/EditorRoom";

import ApplicationContextProvider from './data/ApplicationContextProvider';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Ace editor styles */
import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/ext-prompt";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "ace-builds/src-noconflict/theme-xcode";

import "brace";
import "brace/ext/searchbox";
import "brace/ext/language_tools";

/* Theme variables */
import './theme/variables.css';
import "./theme/theme.css";

setupIonicReact();

export const currentPath = () => {
  const match = window.location.pathname.match(/^\/[^?#]*/);
  return match ? match[0] : '';
}
export const components = {
  login: {
    path: '/',
    Component: Login,
  },
  lobby: {
    path: '/lobby',
    Component: Lobby,
  },
  room: {
    path: '/room',
    Component: Room
  },
  editorRoom: {
    path: '/editor-room',
    Component: EditorRoom,
  },
};
const App: React.FC = () => {
  const [wait, setWait] = useState<boolean>(true);
  const tabBarVisible = useSelector((state: any) => state.uiState.tabBarVisible);

  useEffect(() => {
    const displayChanges = () => setWait(false);
    window.addEventListener('storage', displayChanges);
    return () => window.removeEventListener('storage', displayChanges);
  }, []);

  const getRoutes = () => {
    return (
      <IonRouterOutlet id="main-drawer" animated={false}>
        <NonPrivateRoute path={components.login.path} component={components.login.Component} redirect={components.lobby.path} exact />
        <PrivateRoute path={components.lobby.path} component={components.lobby.Component} redirect={components.login.path} exact />
        <PrivateRoute path={components.room.path} component={components.room.Component} redirect={components.login.path} exact />
        <PrivateRoute path={components.editorRoom.path} component={components.editorRoom.Component} redirect={components.login.path} exact />
        {/* Open in both case i.e auth, not-auth */}
        {/* <Route path={"/open"} component={IonApp} exact /> */}
        <Redirect to={components.login.path} />
      </IonRouterOutlet>
    );
  };

  return (
    <IonApp>
      {wait && <IonLoading isOpen message="Please wait" duration={5000} />}
      <ApplicationContextProvider>
        <IonReactRouter>
          {getRoutes()}
        </IonReactRouter>
      </ApplicationContextProvider>
    </IonApp>
  )
};

export default App;

import TokenClass from "./utils/common/token-state";
import { io, Socket } from "socket.io-client";

const socket: Socket = io(process.env.REACT_APP_SERVER_BACKEND_URL || "", {
  autoConnect: true,
  auth: {
    token: new TokenClass().getToken()?.token || "",
  },
});

export default socket;

import { CLIENT_TIMER_EVENTS } from "@com.xcodeclazz/socket-polling";

export default class TimerClass {
  saveTimer(timer: CLIENT_TIMER_EVENTS.YIELD_TIMER_START | {}): void {
    this.save("watch", JSON.stringify(timer));
  }
  getTimer(): CLIENT_TIMER_EVENTS.YIELD_TIMER_START | null {
    const watch = this.get("watch");
    return watch ? JSON.parse(watch) : null;
  }

  isPrevTimeLeft(): boolean {
    const timer = this.getTimer();
    return timer?.iat ? new Date().getTime() < timer?.iat : false;
  }

  get(key: string): string | null {
    if (localStorage.getItem(key)) return localStorage.getItem(key);
    return null;
  }

  save(key: string, value: any): void {
    if (value == null) return;
    localStorage.setItem(key, value);
  }

  delete = (key: string) => localStorage.removeItem(key);
  clean = () => localStorage.clear();
}

const backend = process.env.REACT_APP_SERVER_BACKEND_URL || ''; // proxy
const codereport = process.env.REACT_APP_SERVER_CODE_REPORT_URL || 'http://localhost:9999/'; // proxy
const monolithic = process.env.REACT_APP_SERVER_MONOLITHIC_URL || 'http://localhost:8087/';

export const routes = {
    // POST: https://code-report.xcodeclazz.com/stage/:id
    // GET: https://code-report.xcodeclazz.com/stage/:id
    // POST: https://code-report.xcodeclazz.com/:id
    // GET: https://code-report.xcodeclazz.com/:id
    POST_CODE_REPORT: `${codereport}:id`,
    GET_CODE_REPORT: `${codereport}:id`
};
import { CLIENT_QUESTION_EVENTS, QuestionAttrs } from "@com.xcodeclazz/socket-polling";
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const questionState = createSlice({
  name: "questionState",
  initialState: {
    templates: [] as any[],
    history: [] as QuestionAttrs[],
  },
  reducers: {
    saveQuestionHistory: (state, action) => {
      const question: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_REPORT = action.payload;
      state.history.unshift(question);
    },
    saveQuestionHistories: (state, action) => {
      const data: CLIENT_QUESTION_EVENTS.YIELD_QUESTION_HISTORY = action.payload;
      if (data.history) state.history = _.reverse(_.cloneDeep(data.history));
    },
    saveQuestionsTemplates: (state, action) => {
      state.templates = action.payload;
    },
    clearQuestionHistories: (state) => {
      state.history = [];
    },
  },
});

export const {
  saveQuestionHistory,
  saveQuestionHistories,
  clearQuestionHistories,
  saveQuestionsTemplates,
} = questionState.actions;

export default questionState.reducer;

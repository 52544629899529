import _ from "lodash";
import AceEditor from "react-ace";
import TimerButton from "../TimerButton";
import { useSelector } from "react-redux";
import { closeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import EditorState from "../../utils/common/editor-state";
import { getEditorTheme } from "../../utils/common/helper";
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import {
  IonItem,
  IonPage,
  IonIcon,
  IonTitle,
  IonSelect,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonTextarea,
  IonSelectOption,
} from "@ionic/react";

export interface ICodePusherModal {
  schedule: number;
  lang: string;
  code: string;
  input: string;
  output: string;
}

const CodePusherModal = ({
  onDismiss,
}: {
  onDismiss: (data?: ICodePusherModal | null, role?: string) => void;
}) => {
  const editorState = new EditorState();
  const inputRef = useRef<HTMLIonTextareaElement>(null);
  const outputRef = useRef<HTMLIonTextareaElement>(null);
  const theme_state: string = useSelector((state: any) => state.uiState.theme);

  const searchParams = new URL(window.location.href).searchParams;
  const lang = searchParams.get('lang');

  const [fontSize, ] = useState<number>(18);
  const [showGutter, ] = useState<boolean>(true);
  const [second, setSecond] = useState<number>(30);
  const [source, setSource] = useState<string>("");
  const [selectedLang, setSelectedLang] = useState<ILanguage>(lang as ILanguage || ILanguage.Python);

  useEffect(() => {}, [theme_state]);

  const onChangeLang = (lang: ILanguage) => setSelectedLang(lang);
  const handleSubmit = (proceed: boolean, reason: string) => {
    if (proceed) {
      const data: ICodePusherModal = {
        code: source,
        schedule: second,
        lang: selectedLang,
        input: inputRef.current?.value?.toString() || "",
        output: outputRef.current?.value?.toString() || "",
      };
      onDismiss(data, reason);
    } else {
      onDismiss(null, reason);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => handleSubmit(false, "cancel")}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Code Pusher</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSubmit(true, "confirm")}>
              Send
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonButtons slot="start">
            <TimerButton text="30" active={second === 30} onClick={() => setSecond(30)} />
            <TimerButton text="60" active={second === 60} onClick={() => setSecond(60)} />
            <TimerButton text="90" active={second === 90} onClick={() => setSecond(90)} />
            <TimerButton text="120" active={second === 120} onClick={() => setSecond(120)} />
            <TimerButton text="300" active={second === 300} onClick={() => setSecond(300)} />
            <TimerButton text="600" active={second === 600} onClick={() => setSecond(600)} />
            <TimerButton text="1200" active={second === 1200} onClick={() => setSecond(1200)} />
          </IonButtons>
          <IonSelect justify="end" placeholder="Language" value={selectedLang} onIonChange={(e) => onChangeLang(e.detail.value)}>
            {_.map(ILanguage, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonTextarea ref={inputRef} placeholder="Please provide input" spellCheck={false} className="text-lg" />
        </IonItem>
        <IonItem>
          <IonTextarea ref={outputRef} placeholder="Please provide output" spellCheck={false} className="text-lg" />
        </IonItem>
        <AceEditor
          name={`ID-${Math.random()}`}
          mode={editorState.getMode(selectedLang)}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={false}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => { editor.renderer.setPadding(10); }}
          style={{ height: "65%", backgroundColor: "transparent" }}
          // value={``}
          // debounceChangePeriod={1000}
          onChange={setSource}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default CodePusherModal;

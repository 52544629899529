import { IonText } from "@ionic/react";
import React, { useState, useEffect } from "react";

export interface ICountdown {
  onTimeUp: () => void;
  futureTime: Date;
  reason: string;
}

const Countdown: React.FC<ICountdown> = ({ futureTime, reason, onTimeUp }) => {
  const [remainingTime, setRemainingTime] = useState<number>(
    futureTime.getTime() - new Date().getTime()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = futureTime.getTime() - new Date().getTime();
      setRemainingTime(newRemainingTime);

      if (newRemainingTime <= 0) {
        clearInterval(intervalId);
        onTimeUp();
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [futureTime]);

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const pad = (num: number): string =>
      num < 10 ? `0${num}` : num.toString();

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  return (
    <div>
      {remainingTime > 0 && (
        <IonText className="font-bold">
          {reason}: {formatTime(remainingTime)}
        </IonText>
      )}
    </div>
  );
};

export default Countdown;

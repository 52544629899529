import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import Header from "../components/common/Header";
import { clearChatHistories } from "../redux/reducers/chatState";
import { clearQuestionHistories } from "../redux/reducers/questionState";
import { clearRaisehandHistories } from "../redux/reducers/raisehandState";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonInput, IonPage, IonRow } from "@ionic/react";

const Lobby: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const name = new URLSearchParams(location.search)?.get('name');
  const roomId = new URLSearchParams(location.search)?.get('roomId');

  const nameRef = React.createRef<HTMLIonInputElement>();
  const langRef = React.createRef<HTMLIonInputElement>();
  const roomIdRef = React.createRef<HTMLIonInputElement>();

  const onEnterHandler = () => {
    const name = nameRef.current?.value?.toString();
    const lang = langRef.current?.value?.toString();
    const roomId = roomIdRef.current?.value?.toString();
    if (name == "" || roomId == "" || lang == "") return;
    history.push(`/room?roomId=${roomId}&name=${name}&lang=${lang}`);
  };

  useEffect(() => {
    if (!roomId) return;
    if (roomIdRef.current) {
      roomIdRef.current.value = roomId;
      roomIdRef.current.disabled = true;
    }
    if (nameRef.current) {
      nameRef.current.value = name;
      // nameRef.current.disabled = true;
    }
  }, [roomId]);

  useEffect(() => {
    dispatch(clearChatHistories());
    dispatch(clearQuestionHistories());
    dispatch(clearRaisehandHistories());
  });

  return (
    <IonPage>
      <Header logoutBtn themeBtn backBtn="/" />
      <IonContent className="ion-padding">
        <section className="h-full w-full">
          <IonGrid className="h-full">
            <IonRow className="h-full">
              <IonCol pushMd="3" size="12" sizeMd="6" className="flex justify-center md:items-center">
                <section className="w-full lg:w-2/3">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonInput ref={roomIdRef} label="Room Id" labelPlacement="floating" fill="outline" placeholder="Room Id" type="text" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonInput ref={nameRef} label="Name" labelPlacement="floating" fill="outline" placeholder="Ex. Gaurav Gupta" type="text" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonInput ref={langRef} label="Lang" labelPlacement="floating" fill="outline" placeholder="java" type="text" />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonButtons className="w-full flex justify-end">
                          <IonButton fill="outline" onClick={onEnterHandler}>Enter</IonButton>
                        </IonButtons>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </section>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Lobby;

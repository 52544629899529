import _ from "lodash";
import { useDispatch } from "react-redux";
import TimerButton from "../../TimerButton";
import { forwardRef, useImperativeHandle } from "react";
import { IQuestionTemplate } from "../../../apis/definations";
import { server_questioned } from "../../../socket-host-emits";
import { EQuestionCategory } from "@com.xcodeclazz/socket-polling";
import { randomId } from "@com.xcodeclazz/monolithic-common/build/functions/utility";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import InputQuestionTemplateModal, { IInputQuestionTemplateModal } from "../InputQuestionTemplateModal";
import {
  IonList,
  IonItem,
  IonCard,
  IonLabel,
  IonButton,
  IonButtons,
  IonTextarea,
  useIonModal,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonRippleEffect,
} from "@ionic/react";

export interface IInputQuestionTemplate {}

const InputQuestionTemplate = forwardRef<
  IInputQuestionTemplate,
  {
    questionTemplate: IQuestionTemplate;
  }
>((props, ref) => {
  useImperativeHandle(ref, () => ({}));
  const dispatch = useDispatch();

  const [present, dismiss] = useIonModal(InputQuestionTemplateModal, {
    onDismiss: (data: IInputQuestionTemplateModal | null, role: string) =>
      dismiss(data, role),
  });

  function openModal() {
    present({
      onWillDismiss: (
        ev: CustomEvent<OverlayEventDetail<IInputQuestionTemplateModal>>
      ) => {
        if (ev.detail.role === "confirm") {
          const question = {
            qid: randomId(10),
            created: Date.now(),
            category: EQuestionCategory.Input,
            text: ev.detail.data?.text || "",
            title: ev.detail.data?.title || "",
            seconds: +(ev.detail.data?.second || 0),
            data: {
              input: {
                answer: ev.detail.data?.answer || "",
              },
            },
          };
          if (ev.detail.data?.code) _.assign(question, { code: ev.detail.data?.code });
          server_questioned(question);
        }
      },
    });
  }

  return (
    <>
      <IonCard className="ion-activatable h-full rounded-lg">
        <IonItem lines="none">
          <IonLabel className="font-bold">Seconds</IonLabel>
          <TimerButton text="30" />
          <TimerButton text="60" active />
          <TimerButton text="100" />
        </IonItem>
        <IonRippleEffect></IonRippleEffect>
        <IonCardHeader>
          <div className="flex flex-row justify-between items-center">
            <IonCardTitle className="capitalize">
              {props.questionTemplate.title}
            </IonCardTitle>
            <IonButtons>
              <IonButton
                fill="clear"
                size="small"
                color="primary"
                onClick={openModal}
              >
                Send
              </IonButton>
            </IonButtons>
          </div>
          <IonCardSubtitle>{props.questionTemplate.text}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none" className="ion-no-padding rounded-lg">
            <IonItem>
              <IonTextarea
                rows={3}
                placeholder={props.questionTemplate.input?.placeholder}
                value={props.questionTemplate.input?.value}
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
});

export default InputQuestionTemplate;
